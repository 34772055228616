<template>
  <div id="newsStoryContent">
    <p id="Text1"></p>
    <p>
      The LEGO Universe team had an amazing time in Copenhagen last week.
      <br>
      <br>
      Over the four days, over 50,000 people visited LEGO World, so the event was a real hit,
      and the team was certainly kept busy!
      <br><br>
      The visitors to the LEGO Universe stand had the chance to build a LEGO model which they’d
      like to see in the game or perhaps build themselves in the game.
      <br><br>
      You can see here a small selection of some of the models that were built.
      <br><br>
      The Brand Trailer was also on show there (as featured on
      <a href="http://www.legouniverse.com/">www.LEGOuniverse.com</a>) which created quite a stir,
      plus many took the opportunity to apply to become closed BETA testers.
      <br><br>
      So for those of you who signed up, keep an eye on your inbox as a BETA key may be coming
      your way very soon.
      <br>
      Keep checking <a href="http://www.legouniverse.com/">www.LEGOuniverse.com</a>
      for up and coming events which the LEGO Universe team will be attending.
      <br><br>
      <!-- picA64D8DB0013B3EDA2F007FE7BDB47218.jpg -->
      <img class="img-large rounded" src="@/assets/news-network/great-times-at-lego-world-copenhagen.jpg">
      <br><br>
      <!-- pic94970CE88646BEF523A5A268E5473382.jpg -->
      <img class="img-large rounded" src="@/assets/news-network/lego-world-copenhagen-1.jpg">
      <br><br>
      <!-- pic3CAD8C34762E28AAD0969C9BF0E09DE7.jpg -->
      <img class="img-large rounded" src="@/assets/news-network/lego-world-copenhagen-2.jpg">
      <br><br>
      <!-- pic422B93D75193EF0A66BB4D302D565662.jpg -->
      <img class="img-large rounded" src="@/assets/news-network/lego-world-copenhagen-3.jpg">
</p><p></p>
















      </div>
</template>
